.LoginForm {
    width: 100%;
    padding: 25px 40px;
    text-align: center;
}

.LoginForm .formContainer {
    width: 100%;
}

.LoginForm .formContainer .headerImage {
    padding-bottom: 20px;
}

.LoginForm .formContainer .headerImage img {
    width: 50px;
}

.LoginForm .formContainer .headerText1 {
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 5px;
}

.LoginForm .formContainer .headerText2 {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;
}

.LoginForm .formContainer form {
    padding-bottom: 25px;
}

.LoginForm .formContainer form .inputContainer {
    padding-bottom: 30px;
}

.LoginForm .formContainer form .inputContainer input {
    padding: 10px;
}

/* adjust placeholder color */
.LoginForm .formContainer form .inputContainer input::placeholder {
    opacity: 0.5;
}

.LoginForm .formContainer form .inputContainer input:focus {
    background-color: rgb(26, 94, 155, 0.05);
    border: 1px solid rgb(26, 94, 155, 0.4);
}

.LoginForm .formContainer form .inputContainer input.firstInput {
    border-radius: 7.5px 7.5px 0 0;
}

.LoginForm .formContainer form .inputContainer input.secondInput {
    border-radius: 0 0 7.5px 7.5px;
    border-top: none;
}

.LoginForm .formContainer form .inputContainer input.secondInput:focus {
    border: 1px solid rgb(26, 94, 155, 0.4);
}

.LoginForm .formContainer form button.submitButton {
    background-color: rgb(26, 94, 155);
    color: white;
    padding: 10px;
    border-radius: 7.5px;
    font-weight: 600;
}

.LoginForm footer {
    font-size: 13px;
}
.FormListItem {
    font-size: 15px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    vertical-align: middle;
    /* background-color: pink; */
    /* cursor: pointer; */
}

@media screen and (max-width: 825px) {
    .FormListItem {
        font-size: 13px;
    }
}

.FormListItem td {
    padding: 5px 0px;
}

.FormListItem td.projectNumber {
    width: 200px;
    font-weight: 600;
    padding-left: 20px;
    text-decoration: underline;
    color: #1A5E9B;
    white-space: nowrap;
}

.FormListItem td.service {
    font-weight: 500;
}

.FormListItem td.creationDate {
    width: 200px;
}

.FormListItem td.creationDate span {
    padding: 5px 7.5px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(27, 43, 65, 0.08);
    color: rgb(25, 36, 52, 0.8);
    white-space: nowrap;
}

@media screen and (max-width: 825px) {
    .FormListItem td.creationDate span {
        font-size: 12px;
    }
    
}

.FormListItem td.formStatus {
    width: 200px;
}

.FormListItem td.formStatus span.formStatusContainer {
    display: inline-block;
    padding: 2.5px 10px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    /* width: 110px; */
    white-space: nowrap;
    /* text-align: center; */
}

@media screen and (max-width: 825px){
    .FormListItem td.formStatus span.formStatusContainer {
        font-size: 12px;
    }
}

.FormListItem td.formStatus span.formStatusContainer span.statusColor {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.FormListItem td.formStatus span.formStatusContainer span.statusColor.new {
    background-color: #CA150C;
    box-shadow: 0 0 5px #E21D12;
}

.FormListItem td.formStatus span.formStatusContainer span.statusColor.inProgress,
.FormListItem td.formStatus span.formStatusContainer span.statusColor.changesSubmitted {
    background-color: #DFB300;
    box-shadow: 0 0 5px #FFCC00;
}

.FormListItem td.formStatus span.formStatusContainer span.statusColor.complete {
    background-color: #0DC783;
    box-shadow: 0 0 5px #158444;
}

.FormListItem td.formStatus span.formStatusContainer span.statusColor.submitted,
.FormListItem td.formStatus span.formStatusContainer span.statusColor.changesApproved {
    background-color: #1A5E9B;
    box-shadow: 0 0 5px #1A5E9B;
}

.FormListItem td.formStatus span.formStatusContainer.new {
    border: 1px solid rgb(226, 29, 18, 0.5);
    color: #CA150C;
}

.FormListItem td.formStatus span.formStatusContainer.inProgress,
.FormListItem td.formStatus span.formStatusContainer.changesSubmitted {
    border: 1px solid rgb(222, 174, 16, 0.5);
    color: #DFB300;
}

.FormListItem td.formStatus span.formStatusContainer.complete {
    border: 1px solid rgb(13, 199, 131, 0.5);
    color: #0DC783;
}

.FormListItem td.formStatus span.formStatusContainer.submitted,
.FormListItem td.formStatus span.formStatusContainer.changesApproved {
    border: 1px solid rgb(26, 94, 155, 0.5);
    color: #1A5E9B;
}

.FormListItem td.actions {
    width: 150px;
}

.FormListItem td.actions .actionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.FormListItem td.actions a {
    text-decoration: none;
}

.FormListItem td.actions button {
    width: fit-content;
    padding: 0px;
    /* margin: 0 auto; */
    margin-right: 10px;
    border-radius: 10px;
}

.FormListItem td.actions button.reviewForm {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid rgb(26, 94, 155, 0.8);
    background-color: #FFFFFF;
    color: #1A5E9B;
}

.FormListItem td.actions button.reviewForm:hover {
    background-color: rgb(26, 94, 155, 0.2);
    color: rgb(26, 94, 155, 0.8);
}

.FormListItem td.actions button.viewForm {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid rgb(28, 48, 74, 0.4);
    background-color: #FFFFFF;
    color: rgb(28, 48, 74, 0.5);
}

.FormListItem td.actions button.viewForm:hover {
    background-color: rgb(28, 48, 74, 0.2);
    color: rgb(28, 48, 74, 0.9);
}

.FormListItem td.actions button.deleteForm:hover {
    background-color: rgb(202, 21, 12, 0.2);
    color: rgb(202, 21, 12, 0.24);
}

.FormListItem td.actions button.generateDoc:hover {
    background-color: rgb(26, 94, 155, 0.2);
    color: rgb(26, 94, 155, 0.24);
}
.FormList {
    height: 100%;
    background-color: #FFFFFF;
    padding-top: 0px;
    border: 0.5px solid rgb(26, 56, 96, 0.1);
    border-radius: 10px;
}

.FormList .formListContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 0px;
}

.FormList .formListContainer .formListTitle {
    font-size: 20px;
    font-weight: 700;
    padding: 2.5px 20px;
}

.FormList .formListContainer .formListTableContainer {
    height: 100%;
    overflow: auto;
}

.FormList .formListContainer .formListTableContainer table.table {
    width: 100%;
    border-radius: 10px;
    border-spacing: 0;
    border-collapse: separate;
    overflow: auto;
}

.FormList .formListContainer .formListTableContainer table.table thead {
    font-size: 16px;
    position: sticky;
    top: 0;
}

.FormList .formListContainer .formListTableContainer table.table thead tr th {
    background-color: #193B67;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.FormList .formListContainer .formListTableContainer table.table thead tr th:first-child {
    padding-left: 20px;
}

.FormList .formListContainer .formListTableContainer table.table thead tr th:last-child {
    padding-right: 20px;
}
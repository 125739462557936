.IntakeFormNotes.notesDropDown.show {
    width: 30vw;
    height: 40vh;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: #FFFFFF;
    border: 1px solid rgb(26, 56, 96, 0.2);
}

.IntakeFormNotes.notesDropDown .noteHeader {
    padding: 2.5px 10px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    font-weight: 600;
    width: 100%;
}

.IntakeFormNotes.notesDropDown .noteContainer {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.IntakeFormNotes.notesDropDown .noteContainer .noteBody {
    border: 1px solid rgb(28, 48, 74, 0.15);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: rgb(25, 59, 103, 0.04);
}

.IntakeFormNotes.notesDropDown .noteContainer .noteBody textarea {
    width: 100%;
    background-color: rgb(25, 59, 103, 0);
    border: none;
    resize: none;
    height: 100%;
    margin: 0px 0px 10px 0px;
    border: none;
    font-size: 13px;
    white-space: break-spaces;
}

.IntakeFormNotes.notesDropDown .noteContainer .noteBody textarea:focus {
    outline: none;
}

.IntakeFormNotes.notesDropDown .noteActionButtons {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
}

.IntakeFormNotes.notesDropDown .noteActionButtons .noteSaveButton {
    background-color: rgb(21, 132, 68, 0.85);
    color: #FFFFFF;
    font-weight: 600;
    font-size: 13px;
    /* border-radius: 7.5px; */
    padding: 5px 20px;
    margin-right: 10px;
}

.IntakeFormNotes.notesDropDown .noteActionButtons .noteSaveButton:hover {
    background-color: rgb(21, 132, 68, 1);
}
.ClientServicesItem {
    /* margin: 10px; */
    font-size: 14px;
    font-weight: 500;
    border: 0.5px solid rgb(26, 56, 96, 0.1);
    border-radius: 7.5px;
}

.ClientServicesItem:not(:last-child) {
    margin-bottom: 10px;
}

.ClientServicesItem.checkedtrue {
    background-color: rgb(26, 94, 155, 0.1);
}

.ClientServicesItem:hover {
    background-color: rgba(128, 128, 128, 0.1);
}

.ClientServicesItem label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
}

.ClientServicesItem label div.inputContainer {
    display: flex;
    align-items: center;
}

.ClientServicesItem label div.inputContainer input {
    margin-right: 10px;
}

.ClientServicesItem label div.inputContainer input[type="checkbox"] {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.ClientServicesItem label div.inputContainer input[type="checkbox"]:checked {
    background-color: #1A5E9B;
}

.ClientServicesItem label div.serviceCode {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #667085;
}
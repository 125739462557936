.ClientList {
    height: 100%;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
}

.ClientList .contentHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ClientList .contentHeader .title {
    font-size: 28px;
    font-weight: 700;
}

.ClientList .contentHeader .actionButtons button.newClientButton {
    height: fit-content;
    width: fit-content;
    border: 1px solid rgb(26, 94, 155, 0.9);
    background-color: #FFFFFF;
    padding: 2.5px 10px 2.5px 2.5px;
    border-radius: 7.5px;
    font-weight: 500;
    color: #1A5E9B;
    font-size: 14px;
}

.ClientList .contentHeader .actionButtons button.newClientButton:hover {
    background-color: rgb(26, 94, 155, 0.1);
}

.ClientList .clientCount {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
}

.ClientList .clientCount span {
    color: #1A5E9B;
    font-weight: 600;
}

.ClientList .clientTable {
    position: relative;
    width: 100%;
    overflow: auto;
    border-radius: 10px;
}

.ClientList table.table {
    width: 100%;
    border-radius: 10px;
    border-spacing: 0;
    border-collapse: separate;
    overflow: auto;
}

.ClientList table.table thead {
    font-size: 16px;
    position: sticky;
    top: 0;
}

.ClientList table.table thead tr th {
    background-color: #193B67;
    color: #FFFFFF;
    font-weight: 500;
    cursor: pointer;
}

.ClientList .table td {
    border: none;
}

.ClientList .table>thead>tr:not(:last-child)>th,
.ClientList .table>thead>tr:not(:last-child)>td,
.ClientList .table>tbody>tr:not(:last-child)>th,
.ClientList .table>tbody>tr:not(:last-child)>td,
.ClientList .table>tfoot>tr:not(:last-child)>th,
.ClientList .table>tfoot>tr:not(:last-child)>td,
.ClientList .table>tr:not(:last-child)>td,
.ClientList .table>tr:not(:last-child)>th,
.ClientList .table>thead:not(:last-child),
.ClientList .table>tbody:not(:last-child),
.ClientList .table>tfoot:not(:last-child) {
    border-bottom: 1px solid rgba(26, 56, 96, 0.1);
}
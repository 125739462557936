.Login {
    height: 100%;
    background-image: url(https://project-intake-assets.s3.ca-central-1.amazonaws.com/Login.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 50px; */
}

.Login .formContainer {
    background-color: #FFFFFF;
    border-radius: 20px;
    width: 40%;
    max-width: 600px;
}

@media screen and (max-width: 768px) {
    .Login .formContainer {
        width: 60%;
    }
    
}

.Login .signupContainer {
    width: 100%;
    margin: auto;
}
.ClientDetails {
    height: 100%;
    padding: 20px 100px 0px 100px;
}

.ClientDetails .topContainer {
    height: 40%;
    /* overflow: auto; */
    display: flex;
    justify-content: space-between;
}

.ClientDetails .topContainer .topContainerContent1 {
    width: 55%;
    margin-right: 20px;
    background-color: #FFFFFF;
    border: 0.5px solid rgb(26, 56, 96, 0.1);
    border-radius: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.ClientDetails .topContainer .topContainerContent1 .head {
    height: 80px;
    width: 100%;
    position: relative;
}

.ClientDetails .topContainer .topContainerContent1 .head img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    padding: 7.5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: pink; */
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .representatives {
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient button.deleteClientButton {
    background-color: #282828;
    border: 2px solid rgb(255, 255, 255, 0.08);
    padding: 5px;
    border-radius: 50%;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient button.deleteClientButton:hover {
    background-color: rgb(255, 255, 255, 0.2);
    border: 2px solid rgb(255, 255, 255, 0.4);
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content {
    padding: 10px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-body {
    display: flex;
    align-items: center;
    padding: 7.5px 0px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-body svg {
    margin-right: 20px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-body .modalBodyContent {
    font-size: 17px;
    font-weight: 600;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-body .modalBodyContent span.modalCompanyName {
    color: #1A5E9B;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-body .modalBodyContent div.deleteConfirmation {
    font-size: 14px;
    font-weight: 400;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid rgb(26, 56, 96, 0.1);
    padding: 0;
    padding-top: 7.5px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button {
    width: fit-content;
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 600;
    margin: 0;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button:first-child {
    margin-right: 10px;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button.confirm {
    background-color: rgb(217, 45, 32, 0.9);
    color: #FFFFFF;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button.confirm:hover {
    background-color: rgb(217, 45, 32, 1);
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button.cancel {
    border: 1px solid #D0D5DD;
    background: none;
    color: #344054;
}

.ClientDetails .topContainer .topContainerContent1 div.head div.floatingContent .deleteClient div.modal .modal-content .modal-footer button.cancel:hover {
    background-color: #F6F7F9;
}

.ClientDetails .topContainer .topContainerContent1 .floatingAvatar {
    width: 100%;
    height: 20px;
}

.ClientDetails .topContainer .topContainerContent1 .floatingAvatar>span {
    position: relative;
    top:-40px;
    left: 20px;
    font-size: 38px;
    font-weight: 600;
    padding: 7.5px 20px;
    border-radius: 50%;
    background-color: #E3E4E6;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 80px);
    overflow: auto;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1  {
    padding: 0px 20px;
    height: 100%;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .clientName {
    font-size: 20px;
    font-weight: 600;
    color: #192434;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 500;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientInfo1 {
    display: flex;
    align-items: center;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientInfo1 .clientEmail {
    margin-right: 10px;
    color: rgb(25, 36, 52, 0.8);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientInfo1 .clientID {
    color: rgb(28, 48, 74, 0.65);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientInfo1 .clientID span {
    color: rgb(28, 48, 74, 1);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientUpdate button.updateClient {
    font-size: 14px;
    color: #1A5E9B;
    font-weight: 600;
    padding: 5px 10px;
    border: 1px solid #2566A0;
    background: none;
    border-radius: 7.5px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section2 .clientUpdate button.updateClient:hover {
    background-color: rgb(37, 102, 160, 0.3);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section3 {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding: 5px 0px;
    border: 1px solid rgb(26, 56, 96, 0.1);
    border-radius: 7.5px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section3 .section3Content {
    width: 50%;
    display: flex;
    padding: 0px 5px;
    font-size: 14px;
    font-weight: 500;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section3 .section3Content:first-child {
    border-right: 2px solid rgb(26, 56, 96, 0.1);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section3 .section3Content svg {
    margin-right: 10px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent1 .section3 .section3Content .section3ContentHeader {
    font-size: 13px;
    color: rgb(25, 36, 52, 0.65);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 {
    height: fit-content;
    padding: 5px 20px;
    margin-top: 10px;
    background-color: rgb(232, 239, 245, 0.5);
    border: 1px solid rgb(26, 56, 96, 0.1);
    border-radius: 0px 0px 10px 10px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .title {
    font-size: 16px;
    font-weight: 600;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content button.viewCredentials {
    width: fit-content;
    padding: 5px 10px;
    background-color: rgb(26, 94, 155, 0.9);
    font-size: 14px;
    font-weight: 500;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content button.viewCredentials:hover {
    background-color: rgb(26, 94, 155, 1);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 2px solid rgb(26, 56, 96, 0.1);
    /* text-align: center; */
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .headerContent {
    display: flex;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .headerContent svg {
    margin-right: 10px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .headerContent .firstHeader {
    font-size: 18px;
    font-weight: 600;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .headerContent .secondHeader {
    font-size: 14px;
    font-weight: 400;
    color: rgb(25, 36, 52, 0.65);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .right svg {
    cursor: pointer;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-header .right svg:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body {
    padding: 20px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body .itemContainer {
    border: 1px solid rgb(26, 56, 96, 0.1);
    border-radius: 7.5px;
    background-color: rgb(26, 94, 155, 0.03);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body .item {
    padding: 7.5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body .item:first-child {
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body .item .itemContent {
    margin-left: 15px;
    font-weight: 500;
    font-size: 15px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body .item .itemContent .label {
    font-weight: 400;
    font-size: 13px;
    color: rgb(25, 36, 52, 0.65);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body form.sendCredentialsForm {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 15px;
    padding: 15px 0px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-body form.sendCredentialsForm ::placeholder {
    opacity: 0.5;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-footer {
    width: 100%;
    border-top: 2px solid rgb(26, 56, 96, 0.1);
    padding: 10px 20px;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-footer .modalButtonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-footer button {
    width: 100%;
    padding: 5px 20px;
    border-radius: 5px;
    font-weight: 600;
    margin: 0;
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-footer button.cancel {
    margin-right: 20px;
    color: #344054;
    background: none;
    border: 1px solid rgb(28, 55, 90, 0.16);
}

.ClientDetails .topContainer .topContainerContent1 .mainContent .mainContent2 .content .modal.viewClientCredentialsModal .modal-content .modal-footer button.cancel:hover {
    background-color: rgb(28, 55, 90, 0.1);
}

.ClientDetails .topContainer .topContainerContent2 {
    width: 45%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 0.5px solid rgb(26, 56, 96, 0.1);
}

.ClientDetails .topContainer .topContainerContent2 .navigation {
    height: fit-content;
    border-bottom: 1px solid #EAECF0;
    margin-bottom: 10px;
}

.ClientDetails .topContainer .topContainerContent2 .navigation button {
    color: #667085;
    border: none;
    width: fit-content;
    background: none;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 0px;
    border-radius: 10px 10px 0px 0px;
}

.ClientDetails .topContainer .topContainerContent2 .navigation button:first-child {
    margin-right: 15px;
}

@media screen and (max-width: 825px) {
    .ClientDetails {
        padding: 20px 50px;
        overflow: auto;
    }

    .ClientDetails .topContainer {
        flex-direction: column;
        height: fit-content;
    }

    .ClientDetails .topContainer .topContainerContent1 {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .ClientDetails .topContainer .topContainerContent2 {
        width: 100%;
        max-height: 50vh;
    }
    
}

.ClientDetails .topContainer .topContainerContent2 .navigation .nav-link.active {
    border-bottom: 2px solid #1A5E9B;
    color: #1A5E9B;
}

.ClientDetails .topContainer .topContainerContent2 .content {
    height: 100%;
}

.ClientDetails .topContainer .topContainerContent2 .content.tab-content {
    height: 100%;
    margin: 0px;
    overflow: auto;
}

.ClientDetails .topContainer .topContainerContent2 .content.tab-content .tab-pane {
    display: none;
}

.ClientDetails .topContainer .topContainerContent2 .content.tab-content .tab-pane.show {
    display: block;
    height: 100%;
    overflow: auto;
}

.ClientDetails .bottomContainer {
    height: 60%;
    padding-top: 20px;
    overflow: auto;
    border-radius: 10px;
}
.NavBar {
    position: relative;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    border-bottom: 1.5px solid #EAECF0;
}

.NavBar .logo a>img {
    width: 45px;
    padding: 5px 0px;
}

.NavBar .rightContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.NavBar .rightContent .newClientButton {
    height: fit-content;
    background-color: rgb(26, 94, 155, 0.9);
    margin-right: 20px;
    padding: 5px 15px 5px 5px;
    border-radius: 7.5px;
    font-weight: 500;
    font-size: 14px;
}

.NavBar .rightContent .newClientButton:hover {
    background-color: rgb(26, 94, 155, 1);
}
.NavBar .rightContent .profileDropDown {
    cursor: pointer;
}

.NavBar .rightContent .profileDropDown .profileDropDownButton {
    padding: 10px;
    border-left: 1.5px solid#EAECF0;
    white-space: nowrap;
}

.NavBar .rightContent .profileDropDown .profileDropDownButton:hover {
    background-color: rgba(26, 94, 155, 0.05);
}

.NavBar .rightContent .profileDropDown .profileDropDown.dropdown-menu.show {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    width: 20vw;
    top: 45px !important;
    right: -70px !important;
}

.NavBar .rightContent .profileDropDown .dropdown-menu div.userInfo {
    padding: 10px 20px;
}

.NavBar .rightContent .profileDropDown .dropdown-item {
    border-top: 2px solid #F6F7F9;
}

.NavBar .rightContent .profileDropDown .dropdown-item:hover {
    background-color: rgba(26, 94, 155, 0.05);
    color: #1A5E9B;
}

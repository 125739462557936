.FormApproveModal .modal-header {
    border-bottom: 2px solid #EAECF0;
}

.FormApproveModal .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
}

.FormApproveModal .modal-title span.formTitle {
    /* font-weight: 500; */
    color: #1A5E9B;
}

.FormApproveModal .modal-footer {
    border-top: 1px solid #EAECF0;
    justify-content: center;
}

.FormApproveModal .modal-footer button {
    width: fit-content;
}

.FormApproveModal .modal-footer button.cancel {
    color: black;
    background: none;
    border: 1px solid rgb(28, 48, 74, 0.15);
    margin-right: 20px;
}

.FormApproveModal .modal-footer button.cancel:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.FormApproveModal .modal-footer button.confirmApprovel {
    color: #FFFFFF;
    background-color: rgb(21, 132, 68, 0.9);
    /* border: 1px solid #1A5E9B; */
}

.FormApproveModal .modal-footer button.confirmApprovel:hover {
    background-color: rgb(21, 132, 68, 1);
}
.ModifyIntakeForm4 .notesContainer button.notesButton {
    width: fit-content;
    background-color: #FFCC00;
    color: #FFFFFF;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 13px;
}

.ModifyIntakeForm4 .notesContainer button.notesButton:hover {
    background-color: #DFB300;
    color: rgb(25, 36, 52, 1);
}
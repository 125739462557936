body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal .modal-dialog {
  max-width: 50%;
}

.headingText {
  color: #192434;
}

.bodyText {
  color: #192434;
}

.primaryText {
  color: #104B81;
}

.secondaryText {
  color: #1B2B41;
}

.tertiaryText {
  color: #8993A1;
}

.disabledText {
  color: #C4CAD3;
}

.errorText {
  color: #CA150C;
}

.warningText {
  color: #DFB300;
}

.successText {
  color: #0A7637;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading .spinner-border {
  width: 6rem;
  height: 6rem;
  font-size: 1.5rem;
  color: #1A5E9B !important;
}

.tableHeaderWithArrow {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.sortIndicatorActive {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 9px;
}

.sortIndicatorInactive {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 9px;
  opacity: 0.3;
}

.primary {
  background-color: #1A5E9B;
}

.primary-50 {
  background-color: #1A5E9B;
  opacity: 0.5;
}

.primary-25 {
  background-color: #1A5E9B;
  opacity: 0.25;
}

.primary-10 {
  background-color: #1A5E9B;
  opacity: 0.1;
}

.error {
  background-color: #E21D12;
}

.error-50 {
  background-color: #E21D12;
  opacity: 0.5;
}

.error-25 {
  background-color: #E21D12;
  opacity: 0.25;
}

.error-10 {
  background-color: #E21D12;
  opacity: 0.1;
}

.warning {
  background-color: #FFCC00;
}

.warning-50 {
  background-color: #FFCC00;
  opacity: 0.5;
}

.warning-25 {
  background-color: #FFCC00;
  opacity: 0.25;
}

.warning-10 {
  background-color: #FFCC00;
  opacity: 0.1;
}

.success {
  background-color: #158444;
}

.success-50 {
  background-color: #158444;
  opacity: 0.5;
}

.success-25 {
  background-color: #158444;
  opacity: 0.25;
}

.success-10 {
  background-color: #158444;
  opacity: 0.1;
}

button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.buttonPrimary {
  background-color: #1A5E9B;
}

.buttonPrimary:hover {
  background-color: #104B81;
}

.buttonError {
  background-color: #E21D12;
}

.buttonError:hover {
  background-color: #CA150C;
}

.buttonWarning {
  background-color: #FFCC00;
}

.buttonWarning:hover {
  background-color: #DFB300;
}

.buttonSuccess {
  background-color: #158444;
}

.buttonSuccess:hover {
  background-color: #0A7637;
}

.logoImage {
  width: 30px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading .spinner-border {
  width: 6rem;
  height: 6rem;
  font-size: 1.5rem;
  /* color: #1A5E9B; */
  color: #192434;
}

.intakeFormReviewGlobal {
  height: 100%;
  overflow: auto;
  color: #192434;
  /* background-color: pink; */
}

.intakeFormReviewGlobal .service {
  font-size: 22px;
  font-weight: 600;
  /* margin-top: 20px; */
}

.intakeFormReviewGlobal .projectNumber {
  font-size: 20px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.intakeFormReviewGlobal .titleOfInvention {
  font-size: 18px;
}

.intakeFormReviewGlobal .titleOfInvention span:first-child {
  font-weight: 600;
}

.intakeFormReviewGlobal .submissionDate {
  font-size: 14px;
  font-weight: 400;
}

.intakeFormReviewGlobal .submissionDate span:first-child {
  font-weight: 600;
}

.intakeFormReviewGlobal .approvalDate {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.intakeFormReviewGlobal .approvalDate span:first-child {
  font-weight: 600;
}

.intakeFormReviewGlobal .sectionTitle {
  font-size: 20px;
  font-weight: 600;
  color: #192434;
  margin-top: 20px;
  border-top: 1px solid rgb(26, 56, 96, 0.1);
}

.intakeFormReviewGlobal .sectionContentL1 {
  padding-left: 20px;
}

.intakeFormReviewGlobal .questionL1 {
  font-size: 16px;
  font-weight: 600;
  color: #192434;
  margin-top: 5px;
}

.intakeFormReviewGlobal .questionL2 {
  font-size: 14px;
  font-weight: 600;
  color: #192434;
}

.intakeFormReviewGlobal .answer {
  background-color: rgb(26, 94, 155, 0.04);
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 15px;
  white-space: break-spaces;
}

.intakeFormReviewGlobal .answer .inventiveElements,
.intakeFormReviewGlobal .answer .patents,
.intakeFormReviewGlobal .answer .trademarks {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0px;
  border-bottom: 1px solid rgb(26, 56, 96, 0.1);
  overflow: auto;
  white-space: break-spaces;
}

.intakeFormReviewGlobal .answer .inventiveElements:last-child,
.intakeFormReviewGlobal .answer .patents:last-child,
.intakeFormReviewGlobal .answer .trademarks:last-child {
  border-bottom: none;
}

.intakeFormReviewGlobal .answer .inventiveElements div:first-child,
.intakeFormReviewGlobal .answer .patents:first-child,
.intakeFormReviewGlobal .answer .trademarks:first-child {
  margin-right: 5px;
  min-width: 150px;
}


/* Global Modify Intake Form styles */
.modifyIntakeFormGlobal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F6F7F9;
  /* overflow: auto; */
  /* border-radius: 7.5px; */
}

.modifyIntakeFormGlobal .upperSection {
  padding: 15px 40px 10px 40px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.modifyIntakeFormGlobal .upperSection .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 10px 0px;
}

.modifyIntakeFormGlobal .upperSection .firstSection {
  display: flex;
  justify-content: flex-start;
  padding: 15px 40px 10px 0px;
}

.modifyIntakeFormGlobal .upperSection .firstSection .serviceTitle {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus {
  display: flex;
  align-items: center;
  padding: 2.5px 7.5px 2.5px 2.5px;
  border-radius: 15px;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.new {
  background-color: rgb(255, 0, 0, 0.15);
  color: #E21D12;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.inProgress,
.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.changesSubmitted {
  background-color: rgb(255, 204, 0, 0.15);
  color: #B18F05;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.submitted,
.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.changesApproved {
  background-color: rgb(26, 94, 155, 0.2);
  color: #1A5E9B;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.changesApproved svg path {
  fill: #1A5E9B;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.complete {
  background-color: rgb(21, 132, 68, 0.15);
  color: #06612D;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus.submitted svg path {
  fill: #1A5E9B;
}

.modifyIntakeFormGlobal .upperSection .firstSection .formStatus span.statusText {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 5px;
}

.modifyIntakeFormGlobal .upperSection .navigation {
  min-width: 50%;
  max-width: 70%;
  justify-content: space-between;
  min-height: 40px;
  overflow: hidden;
  border-radius: 7.5px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
}

.modifyIntakeFormGlobal .upperSection .navigation button.nav-link {
  color: #344054;
  width: 33.33% !important;
  padding: 0px 5px;
  border-radius: 0px;
  font-weight: 700;
  font-size: 14px;
  white-space: wrap;
  border-right: 1px solid #D0D5DD;
}

.modifyIntakeFormGlobal .upperSection .navigation button.nav-link:last-child {
  border-right: 0px;
}

.modifyIntakeFormGlobal .upperSection .navigation button.nav-link:hover {
  color: rgba(0, 0, 0, 1);
  background-color: rgba(128, 128, 128, 0.1);
  /* text-decoration: underline; */
}

.modifyIntakeFormGlobal .upperSection .navigation button.nav-link.active {
  color: #FFFFFF;
  background-color: #1A5E9B;
  /* text-decoration: underline; */
}

.modifyIntakeFormGlobal .upperSection .content {
  height: 100%;
  overflow: auto;
}

.modifyIntakeFormGlobal .upperSection .content form {
  /* background-color: red; */
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.2); */
  overflow-y: auto;
  /* padding: 0px 50px 0px 50px; */
}

.modifyIntakeFormGlobal .upperSection .content form label {
  font-size: 16px;
  margin-bottom: 0px;
}

.modifyIntakeFormGlobal .upperSection .content form input,
.modifyIntakeFormGlobal .upperSection .content form select,
.modifyIntakeFormGlobal .upperSection .content form textarea {
  font-size: 16px;
  white-space: break-spaces;
}

.modifyIntakeFormGlobal .upperSection .content input::placeholder,
.modifyIntakeFormGlobal .upperSection .content textarea::placeholder {
  opacity: 0.5;
}

.modifyIntakeFormGlobal .upperSection .content form .tab-pane {
  display: none; /* Hide all tabs */
  /* padding: 20px 50px 20px 50px; */
}

.modifyIntakeFormGlobal .upperSection .content form .tab-pane.fade.show.active {
  display: block;
  /* overflow: auto; */
  /* padding: 0px; */
}

.modifyIntakeFormGlobal .upperSection .content form .mainContainer {
  margin-top: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1.5px solid #EAECF0;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  background-color: rgb(234, 236, 240, 0.12);
  border-bottom: 1px solid #EAECF0;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionTitle .svgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #EAECF0;
  border-radius: 7.5px;
  padding: 5px;
  margin-right: 10px;
  background-color: #FFFFFF;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent {
  padding: 10px 20px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent div.mergedLabel {
  margin-bottom: 10px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent div.mergedLabel label {
  width: 100%;
  border: 1px solid rgb(28, 48, 74, 0.2);
  border-radius: 7.5px 7.5px 0px 0px;
  border-bottom: none;
  padding: 5px;
  font-weight: 600;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent div.mergedLabel label .itemNum {
  background-color: #8145FF;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  color: #FFFFFF;
  border-radius: 7.5px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent div.mergedLabel input,
.modifyIntakeFormGlobal .upperSection .content form .sectionContent div.mergedLabel textarea{
  width: 100%;
  border: 1px solid rgb(28, 48, 74, 0.2);
  border-radius: 0px 0px 7.5px 7.5px;
  padding: 5px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent button.incrementItem {
  background: none;
  color: #1A5E9B;
  padding: 5px;
  border-radius: 7.5px;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: 600;
  font-size: 15px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent button.incrementItem svg {
  margin-right: 5px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent button.incrementItem:hover {
  background-color: rgb(26, 94, 155, 0.2);
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent button.incrementItem:active {
  background-color: rgb(26, 94, 155, 0.4);
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable th {
  background-color: rgb(26, 94, 155, 0.08);
  border-right:1px solid #EAECF0;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 16px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable th:first-child {
  border-radius: 7.5px 0px 0px 0px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable th:last-child {
  border-radius: 0px 7.5px 0px 0px;
  border-right: none;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable td {
  border: 1px solid #EAECF0;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable td input,
.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable td textarea{
  width: 100%;
  border: none;
  padding: 5px 10px;
  border-radius: 0px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent table.contentTable td:last-child {
  width: 40%;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .formGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .formGroup .formGroupItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .formGroup .formGroupItem:first-child {
  margin-right: 20px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer {
  display: flex;
  margin-top: 10px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  margin-right: 10px;
  border: 1px solid rgb(28, 48, 74, 0.2);
  border-radius: 7.5px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer .radioButton.active {
  border: 1px solid #1A5E9B;
  background-color: rgb(26, 94, 155, 0.15);
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton:last-child {
  margin-right: 0px;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton input[type="radio"] {
  margin-right: 5px;
  cursor: pointer;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: #1A5E9B;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1.5px solid white;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton label {
  font-weight: 500;
  cursor: pointer;
  padding: 5px 15px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modifyIntakeFormGlobal .upperSection .content form .sectionContent .radioButtonContainer div.radioButton label:hover {
  background-color: rgb(26, 94, 155, 0.1);
}

.modifyIntakeFormGlobal .upperSection .content form span.requiredField {
  color: red;
  font-size: large;
}

.modifyIntakeFormGlobal .upperSection .strongFont {
  font-weight: 700;
}

.modifyIntakeFormGlobal .upperSection .content form .smallFont {
  font-size: 13px;
  font-weight: 500;
}

.modifyIntakeFormGlobal .upperSection .content form .blueColorFont {
  color: rgb(26, 94, 155, 1);
}

.modifyIntakeFormGlobal .upperSection .content form .italicBlueColorFont {
  font-style: italic;
  color: rgb(26, 94, 155, 1);
}

.modifyIntakeFormGlobal .upperSection .content form .blueBack {
  background-color: rgb(26, 94, 155, 0.08);
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: -10px;
}

.modifyIntakeFormGlobal .upperSection .content form .technicalSection1 ol {
  list-style: none;
  counter-reset: item;
}

.modifyIntakeFormGlobal .upperSection .content form .technicalSection1 li {
  position: relative;
  counter-increment: item;
  margin-bottom: 10px;
  margin-left: 2px;
}

.modifyIntakeFormGlobal .upperSection .content form .technicalSection1 li:before {
  position: absolute;
  left: -30px;
  margin-right: 10px;
  content: counter(item);
  background-color: rgb(26, 94, 155, 0.1);
  padding: 2px 7.5px;
  border-radius: 5px;
  color: #1A5E9B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer {
  height: 300px;
  /* overflow-y: scroll; */
  /* border: 2px solid rgba(128, 128, 128, 0.2); */
  padding: 2px;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent {
  padding: 10px;
  /* background-color: rgba(128, 128, 128, 0.2); */
  height: 100%;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadMessage {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadInput {
  display: flex;
  justify-content: center;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadPreviewContainer {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadPreviewContainer .fileUploadPreviewContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadPreviewContainer .fileUploadPreviewContent .fileUploadPreviewItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadPreviewContainer .fileUploadPreviewContent .fileUploadPreviewItem .removeFile {
  margin-left: 10px;
  cursor: pointer;
}

.modifyIntakeFormGlobal .upperSection .content form .fileUploadContainer .fileUploadContent .fileUploadPreviewContainer .fileUploadPreviewContent .fileUploadPreviewItem .removeFile svg {
  pointer-events: none;
}

.modifyIntakeFormGlobal .lowerSection {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-radius: 0px 0px 7.5px 7.5px; */
}

.modifyIntakeFormGlobal .lowerSection button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 7.5px;
  width: fit-content;
  white-space: nowrap;
  padding: 5px 25px;
}

.modifyIntakeFormGlobal .lowerSection .tabSwitchNotificationContainer {
  width: 100%;
  font-size: 18px;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px 10px 50px;
  /* background-color: red; */
  /* border-radius: 0px 0px 7.5px 7.5px; */
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext {
  /* overflow: scroll; */
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: pink; */
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer {
  width: 100%;
  /* background-color: red; */
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer .navButton {
  width: fit-content;
  padding: 15px 17.5px;
  border-radius: 7.5px;
  background-color: rgb(26, 94, 155, 0.2);
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer .navButton:hover {
  background-color: rgb(26, 94, 155, 0.3);
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer .navButton:active {
  background-color: rgb(26, 94, 155, 0.4);
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer .navButton:disabled {
  background-color: rgb(26, 94, 155, 0.1);
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .backNext .navigationButtonContainer .navButton:first-child {
  margin-right: 10px;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .cancelButton button {
  background-color: rgb(27, 43, 65, 0.3);
  color: #FFFFFF;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .cancelButton button:hover {
  background-color: rgb(27, 43, 65, 0.5);
  color: #FFFFFF;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .submitButton {
  background-color: rgb(21, 132, 68, 0.9);
  width: fit-content;
  color: #FFFFFF;
  padding: 5px 40px;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .submitButton:hover {
  background-color: #158444;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-header {
  font-size: 20px;
  font-weight: 600;
  border-bottom: none;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-body {
  padding: 10px 20px;
  font-weight: 600;
  border-bottom: none;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer {
  border-top: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer button {
  padding: 5px 40px;
  border-radius: 7.5px;
  margin: 0px 10px;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer button.cancelButton {
  background-color: rgb(138, 146, 166, 0.9);
  color: #FFFFFF;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer button.cancelButton:hover {
  background-color: rgb(138, 146, 166, 1);
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer button.confirmButton {
  background-color: rgb(21, 132, 68, 0.9);
  color: #FFFFFF;
}

.modifyIntakeFormGlobal .lowerSection .navigationButtons .navigationButtonContainer .modal .modal-dialog .modal-content .modal-footer button.confirmButton:hover {
  background-color: #158444;
}
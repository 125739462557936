.UpdateClientForm {
    height: fit-content;
    border-radius: 10px;
}

.UpdateClientForm.modal-dialog {
    min-width: 60%;
}

.UpdateClientForm .modal-content {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.UpdateClientForm .modal-content.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.UpdateClientForm .formContainer .header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    padding: 5px 20px;
}

.UpdateClientForm .formContainer .header .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.UpdateClientForm .formContainer .header .left .second .sec1 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.UpdateClientForm .formContainer .header .left .second .sec2 {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
}

.UpdateClientForm .formContainer .header .right svg {
    cursor: pointer;
}

.UpdateClientForm .formContainer .header .right svg:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.UpdateClientForm .formContainer form {
    font-size: 14px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.UpdateClientForm .formContainer form .formContent {
    overflow: auto;
    padding: 10px 20px;
    text-align: left;
    font-weight: 600;
}

.UpdateClientForm .formContainer form .formContent input, 
.UpdateClientForm .formContainer form .formContent select,
.UpdateClientForm .formContainer form .formContent textarea {
    font-size: 14px;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

.UpdateClientForm .formContainer form .formContent input::placeholder {
    opacity: 0.5;
}

.UpdateClientForm .formContainer form .formContent .groupContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.UpdateClientForm .formContainer form .formContent .groupContainer .groupContainerItem {
    width: 100%;
    padding-right: 15px;
}

.UpdateClientForm .formContainer form .formContent .groupContainer .groupContainerItem:last-child {
    padding-right: 0;
}

.UpdateClientForm .formContainer .formButtonContainer {
    height: 10%;
    padding: 5px 10px;
    height: fit-content;
    border-top: 1px solid rgb(26, 56, 96, 0.1);
}

.UpdateClientForm .formContainer .formButtonContainer button {
    height: fit-content;
    padding: 10px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.UpdateClientForm .formContainer .formButtonContainer button.cancel {
    color: black;
    background: none;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

.UpdateClientForm .formContainer .formButtonContainer button.cancel:hover {
    background-color: rgba(0, 0, 0, 0.05);
}


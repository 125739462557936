.ClientListItem {
    cursor: pointer;
    font-size: 14px;
}


.ClientListItem .companyName {
    font-size: 16px;
    font-weight: 600;
    color: #1A5E9B;
}

.ClientListItem .creationDate span {
    font-size: 13px;
    border: 1px solid rgb(27, 43, 65, 0.1);
    border-radius: 7.5px;
    padding: 5px 10px;
    color: rgb(25, 36, 52, 0.8);
    font-weight: 400;
    white-space: nowrap;
}
.IntakeFormReview {
    height: 100%;
    padding: 20px 100px 0px 100px;
}

.IntakeFormReview .contentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20px;
}

.IntakeFormReview .contentContainer .topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
  
.IntakeFormReview .contentContainer .topContent a.backLink {
    width: fit-content;
    text-decoration: none;
}

.IntakeFormReview .contentContainer .topContent .backLink button.backButton {
    width: fit-content;
    background-color: rgb(138, 146, 166, 0.15);
    color: rgb(25, 36, 52, 0.79);
    padding: 5px 10px;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.IntakeFormReview .contentContainer .topContent .backLink button.backButton:hover {
    background-color: rgb(138, 146, 166, 0.4);
    color: rgb(25, 36, 52, 1);
}

.IntakeFormReview .topContent .formStatus {
    display: flex;
    align-items: center;
    padding: 2.5px 7.5px 2.5px 2.5px;
    border-radius: 15px;
}
  
.IntakeFormReview .topContent .formStatus.new {
    background-color: rgb(255, 0, 0, 0.15);
    color: #E21D12;
  }
  
.IntakeFormReview .topContent .formStatus.inProgress,
.IntakeFormReview .topContent .formStatus.changesSubmitted {
    background-color: rgb(255, 204, 0, 0.15);
    color: #B18F05;
}
  
.IntakeFormReview .topContent .formStatus.submitted,
.IntakeFormReview .topContent .formStatus.changesApproved {
    background-color: rgb(26, 94, 155, 0.2);
    color: #1A5E9B;
}
  
.IntakeFormReview .topContent .formStatus.changesApproved svg path {
    fill: #1A5E9B;
}
  
.IntakeFormReview .topContent .formStatus.complete {
    background-color: rgb(21, 132, 68, 0.15);
    color: #06612D;
}
  
.IntakeFormReview .topContent .formStatus.submitted svg path {
    fill: #1A5E9B;
}
  
.IntakeFormReview .topContent .formStatus span.statusText {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 5px;
}
  

.IntakeFormReview .contentContainer .topContent .notesContainer button.notesButton {
    width: fit-content;
    background-color: #FFCC00;
    color: #FFFFFF;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 13px;
}

.IntakeFormReview .contentContainer .topContent .notesContainer button.notesButton:hover {
    background-color: #DFB300;
    color: rgb(25, 36, 52, 1);
}

.IntakeFormReview .contentContainer .mainContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 7.5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.1);
}

.IntakeFormReview .contentContainer .mainContent .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    border-top: 2px solid rgb(26, 56, 96, 0.1);
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button {
    width: fit-content;
    padding: 5px 20px;
    font-weight: 600;
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.return {
    background-color: rgb(26, 56, 96, 0.4);
    /* border: 1px solid rgb(202, 21, 12, 0.2); */
    color: #FFFFFF;
    margin-right: 50px;
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.return:hover {
    background-color: rgb(26, 56, 96, 0.5);
    /* color: rgb(202, 21, 12, 1); */
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.approve {
    background-color: rgb(21, 132, 68, 0.9);
    padding: 5px 50px;
    /* border: 1px solid rgb(21, 132, 68, 1); */
    color: #FFFFFF;
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.approve:hover {
    background-color: rgb(21, 132, 68, 1);
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.generateProject {
    background-color: rgb(26, 56, 96, 0.9);
    padding: 5px 60px;
    color: #FFFFFF;
}

.IntakeFormReview .contentContainer .mainContent .actionContainer button.generateProject:hover {
    background-color: rgb(26, 56, 96, 1);
}
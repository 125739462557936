.FormGeneration {
    height: 100%;
    font-size: 14px;
}

.FormGeneration form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FormGeneration form .formContent {
    overflow: auto;
    /* margin-bottom: 40px; */
}

.FormGeneration .form input {
    width: 60px;
    font-size: 14px;
}

.FormGeneration form .submitButtonContainer {
    border-top: 1px solid rgb(26, 56, 96, 0.1);
    margin-top: 10px;
    padding: 10px 0px;
}